"use strict";
/**
 * @fileoverview Utility functions intended to help the main script check page state
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkSuccessURL = exports.checkIfMobile = exports.checkIfAppUrl = exports.checkStrikeThrough = void 0;
/**
 * Checks if the target element has a line-through decoration or a sale price element.
 * @param {HTMLElement} parentEle - The target element.
 * @param {HTMLElement} targetEle - The target element.
 * @param {Array<string>} salePriceSelectors - An array of sale price selectors.
 * @returns {boolean} - Returns true if the target element has a line-through decoration or a sale price element, otherwise false.
 */
function checkStrikeThrough(parentEle, targetEle, salePriceSelectors) {
    var hasLineThrough = window.getComputedStyle(targetEle, null).textDecoration.includes('line-through');
    var hasSalePriceElement = false;
    if (salePriceSelectors) {
        for (var i = 0; salePriceSelectors.length > i; i++) {
            var salePriceElement = parentEle.querySelector(salePriceSelectors[i]);
            if (salePriceElement) {
                if (salePriceElement.offsetParent != null) {
                    hasSalePriceElement = true;
                    break;
                }
            }
        }
    }
    return hasLineThrough || hasSalePriceElement;
}
exports.checkStrikeThrough = checkStrikeThrough;
/**
 * Checks if the current URL is an app URL.
 * @param {string} currentUrl - The current URL.
 * @param {string[]} appDomains - The list of app domains.
 * @returns {boolean} True if the current URL is an app URL, otherwise false.
 */
function checkIfAppUrl(currentUrl, appDomains) {
    return appDomains.some(function (domain) { return currentUrl.includes(domain); });
}
exports.checkIfAppUrl = checkIfAppUrl;
/**
* Checks if the current device is a mobile device.
* @returns {boolean} - Returns true if the current device is a mobile device, otherwise false.
*/
function checkIfMobile() {
    if (window.innerWidth < 769) {
        return true;
    }
    else if (location.href.includes('localhost:5000/admin') || location.href.includes('discountobvious/admin')) {
        var mobileSearch = document.querySelector('.mobile-search-page-js');
        var mobilePDP = document.querySelector('.mobile-pdp-js');
        var mobileSearchDisplay = mobileSearch ? window.getComputedStyle(mobileSearch).display : null;
        var mobilePDPDisplay = mobilePDP ? window.getComputedStyle(mobilePDP).display : null;
        return mobilePDPDisplay !== 'none' || mobileSearchDisplay !== 'none';
    }
    else {
        return false;
    }
}
exports.checkIfMobile = checkIfMobile;
/**
 * Checks if the current URL matches the specified success URL and sets a value in the sessionStorage if it does.
 *
 * @param {string} successURL - The URL to check for success.
 *
 * @returns {void}
 */
function checkSuccessURL(successURL) {
    if (successURL && window.location.href.includes(successURL)) {
        sessionStorage.setItem('doSuccess', 'true');
    }
}
exports.checkSuccessURL = checkSuccessURL;
