"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AppDomains_1 = require("./constants/AppDomains");
var calculationUtils_1 = require("./util/calculationUtils");
var helperUtils_1 = require("./util/helperUtils");
var domUtils_1 = require("./util/domUtils");
var state = {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    globalObserverContainer: {},
    strikeStylingEles: []
};
var globals = {
    isAppUrl: undefined,
    isMobile: undefined
};
/**
 * Updates the DOM to show a discount on the specified target element.
 *
 * @param {DiscountObviousConfig} discountObviousOptions - An object containing options for updating the DOM with a discount.
* @param {HTMLElement} parentEle - The parent element of the target element.
* @param {HTMLElement} targetEle - The target element on which to show the discount.
 * @param {number} existingSalePrice - The existing sale price of the item (if applicable
 * @returns {void} - Modifies the DOM by adding a discount and savings information to the target element's parent.
 */
function updateDomWithDiscount(discountObviousOptions, parentEle, targetEle, existingSalePrice) {
    if (!(discountObviousOptions === null || discountObviousOptions === void 0 ? void 0 : discountObviousOptions.discountEnabled)) {
        return;
    }
    var discountAmount = discountObviousOptions.discountAmount, discountType = discountObviousOptions.discountType, strikethrough = discountObviousOptions.strikethrough, appearAtEnd = discountObviousOptions.appearAtEnd, animationDuration = discountObviousOptions.animationDuration, textAnimation = discountObviousOptions.textAnimation, showSavingsType = discountObviousOptions.showSavingsType, showSavings = discountObviousOptions.showSavings;
    var savingsEle;
    if (strikethrough) {
        targetEle.classList.add('strike');
    }
    var containerEle = parentEle;
    var targetParentEle = targetEle.parentElement;
    if (targetParentEle == null)
        return;
    (0, domUtils_1.resetPageState)(containerEle);
    var originalPriceText = targetEle.innerText.trim();
    var originalPrice = parseFloat(originalPriceText.replace(/[^\d.]/g, ''));
    var discountedPriceNumber = existingSalePrice !== null && existingSalePrice !== void 0 ? existingSalePrice : (0, calculationUtils_1.calculateDiscount)(originalPrice, discountAmount, discountType);
    var discountedPrice = "$".concat(discountedPriceNumber);
    var savingsText = (0, calculationUtils_1.getSavingsText)(showSavings, showSavingsType, discountAmount, discountType, originalPrice, discountedPriceNumber);
    var takeActionTextEle = (0, domUtils_1.createTakeActionText)(discountObviousOptions, discountedPrice);
    // HERE consider moving / refactoring this logic
    if (savingsText) {
        savingsEle = document.createElement('span');
        savingsEle.classList.add('discount-obvious-text-savings');
        savingsEle.appendChild(document.createTextNode(savingsText));
        targetParentEle.append(savingsEle);
    }
    targetParentEle.append(takeActionTextEle);
    window.setTimeout(function () {
        var discountTextAnimation = textAnimation || 'no-animation';
        takeActionTextEle.classList.add('discount-obvious-text-show');
        takeActionTextEle.classList.add(discountTextAnimation);
        if (savingsEle != null) {
            savingsEle.classList.add('discount-obvious-text-savings-show');
            savingsEle.classList.add(discountTextAnimation);
        }
    }, appearAtEnd ? Number(animationDuration) : 0);
}
/**
 * Processes the target elements to show discounts.
 * @param {DiscountObviousConfig} discountObviousOptions - An object containing options for updating the DOM with a discount.
 * @param {string} targetEleSelector - Selector for the target element on which to show the discount.
 * @param {HTMLElement} parentEle - The parent element of the target element.
 * @returns {void} - Modifies the DOM by adding discounts and savings information to the target elements' parents.
 */
function processTargetElements(discountObviousOptions, targetEleSelectors, parentEle) {
    var salePriceSelectors = discountObviousOptions.salePriceSelectors, animateWhenInView = discountObviousOptions.animateWhenInView, productContainerSelectors = discountObviousOptions.productContainerSelectors;
    targetEleSelectors.forEach(function (targetEleSelector) {
        var targetEles = parentEle.querySelectorAll(targetEleSelector);
        targetEles.forEach(function (targetEle, index) {
            var _a;
            if (targetEle.classList.contains('do-disabled')) {
                return;
            }
            // const hasStrikeThrough: boolean = checkStrikeThrough(parentEle, targetEle, salePriceSelectors)
            var salePriceElement = (0, domUtils_1.checkSalePrice)(parentEle, salePriceSelectors);
            var existingSalePrice = null;
            if (salePriceElement && !salePriceElement.classList.contains('do-modified')) {
                var salePriceString = targetEle.innerText.trim();
                existingSalePrice = parseFloat(salePriceString.replace(/[^\d.]/g, ''));
                var classesToAdd_1 = [];
                targetEle.classList.forEach(function (className) {
                    if (className.startsWith('strike')) {
                        classesToAdd_1.push(className);
                    }
                });
                (_a = salePriceElement.classList).add.apply(_a, classesToAdd_1);
                salePriceElement.classList.add('do-modified');
                targetEle.style.display = 'none';
                targetEle = salePriceElement;
                (0, domUtils_1.findAndModify)(targetEle, productContainerSelectors);
            }
            if (animateWhenInView) {
                var isObserved = targetEle.getAttribute('data-do-observed');
                if (!isObserved) {
                    var observer = new IntersectionObserver(function (entries, observer) {
                        entries.forEach(function (entry) {
                            if (entry.isIntersecting) {
                                observer.disconnect();
                                updateDomWithDiscount(discountObviousOptions, parentEle, targetEle, existingSalePrice);
                                (0, domUtils_1.setSpecificStrikeStyling)(targetEle, state.strikeStylingEles);
                            }
                        });
                    });
                    if ((0, calculationUtils_1.isInViewport)(targetEle)) {
                        updateDomWithDiscount(discountObviousOptions, parentEle, targetEle, existingSalePrice);
                        (0, domUtils_1.setSpecificStrikeStyling)(targetEle, state.strikeStylingEles);
                    }
                    else {
                        observer.observe(targetEle);
                    }
                    targetEle.setAttribute('data-do-observed', 'true');
                }
            }
            else {
                // if (!hasStrikeThrough) {
                updateDomWithDiscount(discountObviousOptions, parentEle, targetEle, existingSalePrice);
                // }
            }
        });
    });
}
/**
 * Disables elements matching the specified selectors if they meet certain conditions.
 *
 * @param discountObviousOptions The discount obvious configuration object.
 * @param discountObviousOptions.productContainerSelectors An array of CSS selectors for product containers.
 * @param discountObviousOptions.excludedSKUs An array of SKUs to exclude from the discount.
 * @param discountObviousOptions.includedSKUs An array of SKUs to include in the discount.
 */
function disableElementIfNeeded(discountObviousOptions) {
    var productContainerSelectors = discountObviousOptions.productContainerSelectors;
    if (productContainerSelectors && productContainerSelectors.length > 0) {
        productContainerSelectors.forEach(function (selector) {
            var elements = document.querySelectorAll(selector);
            elements.forEach(function (element) {
                var pid = (0, calculationUtils_1.getPID)(element, null, null);
                if (pid && discountObviousOptions.excludedSKUs && discountObviousOptions.excludedSKUs.length > 0 && discountObviousOptions.excludedSKUs.includes(pid)) {
                    (0, domUtils_1.toggleDisabledFlag)(element, discountObviousOptions, true);
                }
                else if (pid && discountObviousOptions.includedSKUs && discountObviousOptions.includedSKUs.length > 0 && !discountObviousOptions.includedSKUs.includes(pid)) {
                    (0, domUtils_1.toggleDisabledFlag)(element, discountObviousOptions, true);
                }
                else {
                    (0, domUtils_1.toggleDisabledFlag)(element, discountObviousOptions, false);
                }
            });
        });
    }
}
/**
 * Adds default sale price selectors to a DiscountObviousConfig object if they are not already specified.
 *
 * @param {DiscountObviousConfig} discountObviousOptions - The DiscountObviousConfig object to add selectors to.
 * @returns {void} This function does not return a value.
 */
function addDefaultSelectors(discountObviousOptions) {
    if (!discountObviousOptions.salePriceSelectors) {
        discountObviousOptions.salePriceSelectors = [];
    }
    discountObviousOptions.salePriceSelectors.push('.product-tile del .value');
}
/**
 * Updates the pricing on the page based on the discountObviousOptions configuration.
 * This is the main init function that powers the client side of DO
 * @param {DiscountObviousConfig} discountObviousOptions - An object containing options for updating the pricing on the page.
 * @returns {void} - Modifies the DOM by updating the pricing of the target elements.
 */
function updatePagePricing(discountObviousOptions) {
    var targetEleSelectors = discountObviousOptions.targetEleSelectors, previewMode = discountObviousOptions.previewMode, showBadges = discountObviousOptions.showBadges, productContainerSelectors = discountObviousOptions.productContainerSelectors;
    if (previewMode) {
        var urlParams = new URLSearchParams(location.search);
        if (!urlParams.has('dopreview')) {
            return;
        }
    }
    addDefaultSelectors(discountObviousOptions);
    disableElementIfNeeded(discountObviousOptions);
    productContainerSelectors.forEach(function (selector) {
        var productContainers = document.querySelectorAll(selector);
        // @ts-expect-error
        productContainers.forEach(function (productContainer) {
            var isEligibleForThisDiscount = (0, calculationUtils_1.checkEligibility)(discountObviousOptions, productContainer);
            if (isEligibleForThisDiscount) {
                if (showBadges) {
                    (0, domUtils_1.createBadges)(discountObviousOptions, productContainer);
                }
                processTargetElements(discountObviousOptions, targetEleSelectors, productContainer);
            }
        });
    });
}
/**
 * Performs pre-run checks on the discount configuration to determine if the discount should be applied.
 *
 * @param {DiscountObviousConfig} discountConfig - An object containing the discount configuration options.
 *
 * @returns {boolean} - A boolean indicating whether the discount should be applied.
 */
function preRunChecks(discountConfig) {
    var now = new Date();
    if (!discountConfig.discountEnabled) {
        return false;
    }
    // Check if the discount start date is in the future, and return false if so
    if ((discountConfig === null || discountConfig === void 0 ? void 0 : discountConfig.startDate) !== '') {
        try {
            var startDate = new Date(discountConfig.startDate);
            if (startDate > now) {
                return false;
            }
        }
        catch (e) {
            console.log('DO: ' + JSON.stringify(e));
        }
    }
    // Check if the discount end date has passed, and return false if so
    if ((discountConfig === null || discountConfig === void 0 ? void 0 : discountConfig.endDate) !== '') {
        try {
            var endDate = new Date(discountConfig.endDate);
            if (endDate < now) {
                return false;
            }
        }
        catch (e) {
            console.log('DO: ' + JSON.stringify(e));
        }
    }
    // Check if the discount has already been successfully applied, and return false if so
    // TODO wouldn't work with multiple successes, probably not important for now
    var sessionVariable = sessionStorage.getItem('doSuccess');
    if (discountConfig.successURL !== null && discountConfig.successURL && sessionVariable && sessionVariable !== 'false') {
        return false;
    }
    // Return true if none of the pre-run checks failed
    return true;
}
/**
 * Updates global variables based on the current window location and device type.
 *
 * @param {Object} globals - An object containing global variables.
 * @param {Array.<string>} appDomains - An array of app domain names.
 *
 * @returns {void} - Updates the global variables as a side effect.
 */
function updateGlobalVariables(globals, appDomains) {
    if (typeof globals.isAppUrl === 'undefined') {
        globals.isAppUrl = (0, helperUtils_1.checkIfAppUrl)(window.location.href, appDomains);
    }
    globals.isMobile = (0, helperUtils_1.checkIfMobile)();
}
/**
 * Applies discount updates to the page based on the provided discounts.
 * This is the first function that calls other functions for DO's client script.
 * @param {DiscountObviousConfig | DiscountObviousConfig[]} discountsArg - A discount configuration object or an array of discount configuration objects.
 *
 * @returns {void} - Modifies the DOM by updating the pricing of the target elements.
 */
function discountObvious(discountsArg) {
    var discountsArray = [];
    if (Array.isArray(discountsArg)) {
        discountsArray = discountsArray.concat(discountsArg);
    }
    else if (typeof discountsArg === 'object' && discountsArg !== null) {
        discountsArray.push(discountsArg);
    }
    if (discountsArray.length === 0) {
        return;
    }
    updateGlobalVariables(globals, AppDomains_1.AppDomains);
    (0, domUtils_1.setStrikeTextDOM)(discountsArray[0].targetEleSelectors);
    var globalStrikeStylingString = (0, domUtils_1.createGlobalStrikeStylingString)(discountsArray[0]);
    if (globalStrikeStylingString !== null) {
        (0, domUtils_1.setGlobalStrikeStyling)(globalStrikeStylingString);
    }
    // Process each discount configuration object in discountsArray
    for (var i = 0; i < discountsArray.length; i++) {
        var discountConfig = discountsArray[i];
        var shouldRunFunction = preRunChecks(discountConfig);
        if (shouldRunFunction) {
            var discountObviousOptions = (0, calculationUtils_1.getMobileOrDesktopDiscountConfig)(discountConfig, globals.isMobile);
            updatePagePricing(discountObviousOptions);
            (0, helperUtils_1.checkSuccessURL)(discountObviousOptions.successURL);
        }
        else {
            continue;
        }
    }
}
exports.default = discountObvious;
