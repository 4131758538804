"use strict";
/**
 * @fileoverview Utility functions that directly manipulate the DOM.
 * Functions or logic that performs calculations should be placed in another file.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBadges = exports.createTakeActionText = exports.resetPageState = exports.decodeString = exports.findAndModify = exports.setSpecificStrikeStyling = exports.setGlobalStrikeStyling = exports.checkSalePrice = exports.toggleDisabledFlag = exports.createGlobalStrikeStylingString = exports.setStrikeTextDOM = void 0;
/**
 * Sets the strike text in the DOM based on the discount configuration.
 * @param {string[]} targetEleSelectors - The array of target element selectors.
 */
function setStrikeTextDOM(targetEleSelectors) {
    targetEleSelectors.forEach(function (targetEleSelector) {
        document.querySelectorAll(targetEleSelector).forEach(function (targetEle, index) {
            var _a;
            var priceText = (_a = targetEle.textContent) === null || _a === void 0 ? void 0 : _a.trim();
            if (!priceText)
                return;
            var priceSpan = document.createElement('span');
            var priceTextNode = document.createTextNode(priceText);
            var strikeClass = "strike-".concat(index);
            priceSpan.append(priceTextNode);
            targetEle.textContent = '';
            targetEle.append(priceSpan);
            targetEle.classList.add(strikeClass);
        });
    });
}
exports.setStrikeTextDOM = setStrikeTextDOM;
/**
 * Creates the global strike styling string from the discount configuration.
 * @param {DiscountObviousConfig} discountConfig - The discount configuration object.
 * @returns {string|null} The global strike styling string or null if any value is missing.
 */
function createGlobalStrikeStylingString(discountConfig) {
    var animationDuration = discountConfig.animationDuration, strikeThroughColor = discountConfig.strikeThroughColor, strikeThroughHeight = discountConfig.strikeThroughHeight, strikeThroughRotation = discountConfig.strikeThroughRotation;
    if (animationDuration && strikeThroughColor && strikeThroughHeight && strikeThroughRotation) {
        return "<style>.strike::after{animation-duration:".concat(animationDuration, "ms; background: ").concat(strikeThroughColor, "; height: ").concat(strikeThroughHeight, "; transform: rotate(").concat(strikeThroughRotation, ")}</style>");
    }
    return null;
}
exports.createGlobalStrikeStylingString = createGlobalStrikeStylingString;
/**
 * Adds the "do-disabled" class to all elements matching the specified selectors
 * within the given parent element.
 *
 * @param parentEle The parent element to search within.
 * @param discountConfig The discount configuration object.
 * @param discountConfig.badgeContainerSelectors An array of CSS selectors for badge containers.
 * @param discountConfig.targetEleSelectors An array of CSS selectors for target elements.
 * @param disable Whether to disable or enable the elements.
 */
function toggleDisabledFlag(parentEle, discountConfig, disable) {
    var badgeContainerSelectors = discountConfig.badgeContainerSelectors, targetEleSelectors = discountConfig.targetEleSelectors;
    badgeContainerSelectors === null || badgeContainerSelectors === void 0 ? void 0 : badgeContainerSelectors.forEach(function (badgeContainerSelector) {
        var badgeContainers = parentEle.querySelectorAll(badgeContainerSelector);
        if (badgeContainers) {
            badgeContainers.forEach(function (badgeContainer) {
                if (disable) {
                    badgeContainer.classList.add('do-disabled');
                }
                else {
                    badgeContainer.classList.remove('do-disabled');
                }
            });
        }
    });
    targetEleSelectors === null || targetEleSelectors === void 0 ? void 0 : targetEleSelectors.forEach(function (targetEleSelector) {
        var targetEles = parentEle.querySelectorAll(targetEleSelector);
        if (targetEles) {
            targetEles.forEach(function (targetEle) {
                if (disable) {
                    targetEle.classList.add('do-disabled');
                }
                else {
                    targetEle.classList.remove('do-disabled');
                }
            });
        }
    });
}
exports.toggleDisabledFlag = toggleDisabledFlag;
/**
 * Checks if the target element has a sale price by searching for elements with sale price selectors
 * inside elements with price container selectors, starting from the target element's closest ancestor.
 * @param {HTMLElement} parentEle - An array of CSS selectors for elements that contain a price.
 * @param {string[]} salePriceSelectors - An array of CSS selectors for elements that indicate a sale price.
 * @returns {HTMLElement|null} - The sale price element or null if no sale price element is found.
 */
function checkSalePrice(parentEle, salePriceSelectors) {
    var newTargetEle = null;
    salePriceSelectors === null || salePriceSelectors === void 0 ? void 0 : salePriceSelectors.forEach(function (salePriceSelector) {
        if (parentEle) {
            var salePriceElement = parentEle.querySelector(salePriceSelector);
            if (salePriceElement) {
                newTargetEle = salePriceElement;
            }
        }
    });
    return newTargetEle;
}
exports.checkSalePrice = checkSalePrice;
/**
 * Sets the global strike styling based on the discount configuration.
 * @param {string} globalStrikeStylingString - A string that represents valid CSS that we will insert into the head of the DOM.
 */
function setGlobalStrikeStyling(globalStrikeStylingString) {
    if (globalStrikeStylingString) {
        document.head.insertAdjacentHTML('beforeend', globalStrikeStylingString);
    }
}
exports.setGlobalStrikeStyling = setGlobalStrikeStyling;
/**
 * Calculates the horizontal distance between the left edge of an HTML element and the left edge of its containing element.
 * @param {HTMLElement} element - The HTML element whose left edge distance to calculate.
 * @param {HTMLElement} container - The HTML element that contains the element whose distance to calculate.
 * @returns {number} - The horizontal distance between the left edges of the element and its containing element.
 */
function getElementLeftRelativeToContainer(element, container) {
    if (container) {
        var elementRect = element.getBoundingClientRect();
        var containerRect = container.getBoundingClientRect();
        var relativeLeft = elementRect.left - containerRect.left;
        return relativeLeft;
    }
    else {
        return element.offsetLeft;
    }
}
/**
 * Returns the width of an HTML element in pixels.
 * @param {HTMLElement} element - The HTML element whose width to calculate.
 * @returns {number} - The width of the element in pixels.
 */
function getElementWidth(element) {
    var elementRect = element.getBoundingClientRect();
    var width = elementRect.width;
    return width;
}
/**
 * Sets the specific strike styling for the target element.
 * @param {HTMLElement} targetEle - The target element.
 * @param {string[]} strikeStylingEles - An array of strike styling elements.
 *  * @returns {void}
 */
function setSpecificStrikeStyling(targetEle, strikeStylingEles) {
    var _a;
    var strikeNumberRegex = /strike-\d+/;
    var strikeClass = (_a = targetEle.className.match(strikeNumberRegex)) === null || _a === void 0 ? void 0 : _a[0];
    if (!strikeClass || strikeStylingEles.includes(strikeClass))
        return;
    strikeStylingEles.push(strikeClass);
    var strikeClassSelector = '.' + strikeClass;
    var maxWidth = "max-width: ".concat(getElementWidth(targetEle), "px;");
    var leftOffset = 'left: 0px'; // `left: ${getElementLeftRelativeToContainer(targetEle, targetEle.parentElement)}px;`
    var styleString = "<style>".concat(strikeClassSelector, "::after{").concat(maxWidth, " ").concat(leftOffset, "</style>");
    document.head.insertAdjacentHTML('beforeend', styleString);
}
exports.setSpecificStrikeStyling = setSpecificStrikeStyling;
/**
 * Recursively removes text decoration from a given HTML element and its children,
 * up to a specified target element.
 *
 * @param {HTMLElement} ele - The element to remove text decoration from.
 * @param {HTMLElement} targetEle - The element to stop removing text decoration at.
 * @returns {void}
 */
function removeTextDecorationRecursive(ele, targetEle) {
    if (ele === targetEle)
        return;
    if (ele instanceof HTMLElement) {
        ele.style.textDecoration = 'unset';
    }
    if (ele.firstElementChild) {
        removeTextDecorationRecursive(ele.firstElementChild, targetEle);
    }
    if (ele.nextElementSibling) {
        removeTextDecorationRecursive(ele.nextElementSibling, targetEle);
    }
}
/**
 * Finds the first parent element of a given HTML element that matches any of the
 * specified selectors, and removes text decoration from it and its children
 * up to the given element.
 *
 * @param {HTMLElement} targetEle - The element to remove text decoration from.
 * @param {string[]} productContainerSelectors - An array of CSS selectors to search for the parent element.
 * @returns {void}
 */
function findAndModify(targetEle, productContainerSelectors) {
    for (var _i = 0, productContainerSelectors_1 = productContainerSelectors; _i < productContainerSelectors_1.length; _i++) {
        var selector = productContainerSelectors_1[_i];
        var parent_1 = targetEle === null || targetEle === void 0 ? void 0 : targetEle.closest(selector);
        if (parent_1) {
            removeTextDecorationRecursive(parent_1, targetEle);
            break;
        }
    }
}
exports.findAndModify = findAndModify;
/**
 * Decodes a string that was encoded for safe storage in a Postgres database.
 *
 * @param {string} input - The encoded string to be decoded.
 * @returns {string} The decoded string.
 */
function decodeString(input) {
    var decodedString = decodeURIComponent(input);
    // Manually decode the characters ! ' ( ) *
    decodedString = decodedString
        .replace(/%21/g, '!')
        .replace(/%27/g, "'")
        .replace(/%2E/g, '.')
        .replace(/%22/g, '"');
    return decodedString;
}
exports.decodeString = decodeString;
/**
 * Resets the page state by removing the discount-obvious-text and discount-obvious-text-savings elements.
 * @param {Element} ele - The element to reset.
 */
function resetPageState(ele) {
    var _a, _b;
    (_a = ele === null || ele === void 0 ? void 0 : ele.querySelector('.discount-obvious-text')) === null || _a === void 0 ? void 0 : _a.remove();
    (_b = ele === null || ele === void 0 ? void 0 : ele.querySelector('.discount-obvious-text-savings')) === null || _b === void 0 ? void 0 : _b.remove();
}
exports.resetPageState = resetPageState;
/**
 * Creates a take action text element based on the discountTextType.
 * @param {DiscountObviousConfig} discountObviousOptions - The discount obvious options configuration object.
 * @param {string} discountedPrice - String representation of the discounted price.
 * @returns {HTMLElement} The take action text element.
 */
function createTakeActionText(discountObviousOptions, discountedPrice) {
    var discountText = discountObviousOptions.discountText, textColor = discountObviousOptions.textColor, discountTextType = discountObviousOptions.discountTextType, discountLink = discountObviousOptions.discountLink, discountJavascript = discountObviousOptions.discountJavascript;
    var takeActionTextEle;
    // const discountTextNode: Text = document.createTextNode(discountedPrice + ' ' + discountText)
    if (discountTextType === 'plainText') {
        takeActionTextEle = document.createElement('span');
    }
    else if (discountTextType === 'link') {
        takeActionTextEle = document.createElement('a');
        takeActionTextEle.href = discountLink;
        takeActionTextEle.classList.add('discount-obvious-text-link');
    }
    else if (discountTextType === 'javascript') {
        takeActionTextEle = document.createElement('span');
        takeActionTextEle.classList.add('discount-obvious-text-link');
        takeActionTextEle.addEventListener('click', function () {
            // eslint-disable-next-line no-eval
            eval(discountJavascript);
        });
    }
    var priceElement = document.createElement('span');
    priceElement.classList.add('discount-obvious-text-price');
    priceElement.innerText = discountedPrice + ' ';
    var priceText = document.createElement('span');
    priceText.classList.add('discount-obvious-text-price-text');
    priceText.innerText = decodeString(discountText);
    takeActionTextEle === null || takeActionTextEle === void 0 ? void 0 : takeActionTextEle.appendChild(priceElement);
    takeActionTextEle === null || takeActionTextEle === void 0 ? void 0 : takeActionTextEle.appendChild(priceText);
    takeActionTextEle === null || takeActionTextEle === void 0 ? void 0 : takeActionTextEle.classList.add('discount-obvious-text');
    if (textColor && takeActionTextEle) {
        takeActionTextEle.style.color = textColor;
    }
    // @ts-expect-error
    return takeActionTextEle;
}
exports.createTakeActionText = createTakeActionText;
/**
 * Creates discount badges and appends them to the specified containers.
 *
 * @param {DiscountObviousConfig} discountObviousOptions - An object containing options for creating discount badges.
 * @returns {void} - Modifies the DOM by adding discount badges to the specified containers.
 */
function createBadges(discountObviousOptions, productContainer) {
    var badgeContainerSelectors = discountObviousOptions.badgeContainerSelectors, badgeBorderRadius = discountObviousOptions.badgeBorderRadius, badgeText = discountObviousOptions.badgeText, badgeTextSize = discountObviousOptions.badgeTextSize, badgePosition = discountObviousOptions.badgePosition, badgeBackgroundColor = discountObviousOptions.badgeBackgroundColor, badgeTextColor = discountObviousOptions.badgeTextColor, badgeLeftOffset = discountObviousOptions.badgeLeftOffset, badgeRightOffset = discountObviousOptions.badgeRightOffset, badgeTopOffset = discountObviousOptions.badgeTopOffset, badgeBottomOffset = discountObviousOptions.badgeBottomOffset;
    badgeContainerSelectors.forEach(function (badgeContainerSelector) {
        var badgeContainerEles = productContainer.querySelectorAll(badgeContainerSelector);
        badgeContainerEles.forEach(function (badgeContainerEle) {
            if (badgeContainerEle instanceof HTMLElement && !badgeContainerEle.classList.contains('do-disabled')) {
                var badgeSpan = document.createElement('span');
                var badgeTextNode = document.createTextNode(badgeText);
                badgeContainerEle.style.position = 'relative';
                badgeSpan.classList.add('do-badge');
                badgeSpan.style.color = badgeTextColor;
                badgeSpan.appendChild(badgeTextNode);
                badgeSpan.style.backgroundColor = badgeBackgroundColor;
                badgeSpan.style.borderRadius = "".concat(badgeBorderRadius, "px");
                badgeSpan.style.fontSize = badgeTextSize;
                badgeContainerEle.appendChild(badgeSpan);
                if (badgePosition === 'do-badge-center') {
                    var img = badgeContainerEle.querySelector('img');
                    var xOffset = img ? Math.ceil((img.width - badgeSpan.clientWidth) / 2) : Math.ceil((badgeContainerEle.clientWidth - badgeSpan.clientWidth) / 2);
                    badgeSpan.style.left = "".concat(xOffset, "px");
                }
                badgeSpan.classList.add(badgePosition);
                badgeSpan.style.marginTop = "".concat(badgeTopOffset, "px");
                badgeSpan.style.marginBottom = "".concat(badgeBottomOffset, "px");
                badgeSpan.style.marginLeft = "".concat(badgeLeftOffset, "px");
                badgeSpan.style.marginRight = "".concat(badgeRightOffset, "px");
                badgeContainerEle.classList.add('do-disabled');
            }
        });
    });
}
exports.createBadges = createBadges;
