"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkEligibility = exports.getCategoryId = exports.getMobileOrDesktopDiscountConfig = exports.getPID = exports.getParentElement = exports.getSavingsText = exports.calculateDiscount = exports.isInViewport = void 0;
/**
 * Determines if the element is in the viewport.
 * @param element - The DOM element.
 * @returns True if the element is in the viewport, otherwise false.
 */
function isInViewport(element) {
    var rect = element.getBoundingClientRect();
    return (rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
        rect.bottom > 0 &&
        rect.right > 0);
}
exports.isInViewport = isInViewport;
/**
 * Calculates the discounted price based on the discount type.
 * @param originalPrice - The original price.
 * @param discountAmount - The discount amount.
 * @param discountType - The type of discount ('fixed-amount' or 'percentage').
 * @returns The discounted price, or 'E.E' in case of an error state.
 */
function calculateDiscount(originalPrice, discountAmount, discountType) {
    function percentageDiscount(originalPrice, percentageOff) {
        return parseFloat((originalPrice * ((100 - percentageOff) / 100)).toFixed(2));
    }
    function fixedAmountDiscount(originalPrice, discountAmount) {
        return parseFloat((originalPrice - discountAmount).toFixed(2));
    }
    if (discountType === 'fixed-amount') {
        var fixedAmount = fixedAmountDiscount(originalPrice, discountAmount);
        return fixedAmount > 0 ? fixedAmount : 0;
    }
    else if (discountType === 'percentage') {
        return percentageDiscount(originalPrice, discountAmount);
    }
    else {
        throw new Error('Invalid discount type provided'); // Error state
    }
}
exports.calculateDiscount = calculateDiscount;
/**
 * Generates savings text based on the provided discount information.
 *
 * @param {boolean} showSavings - A boolean value indicating whether to display the savings text.
 * @param {string} showSavingsType - A string value indicating whether to show savings as a percentage or an amount.
 * @param {number} discountAmount - The discount amount.
 * @param {string} discountType - The type of discount (percentage or amount).
 * @param {number} originalPrice - The original price of the item.
 * @param {function} calculateFn - A function to calculate the discounted price.
 *
 * @returns {?string} - A string containing the savings text or null if showSavings is false.
 */
function getSavingsText(showSavings, showSavingsType, discountAmount, discountType, originalPrice, discountedPrice) {
    var savingsText = null;
    if (showSavings) {
        if (showSavingsType === 'showSavingsAmount') {
            var priceDifference = (originalPrice - discountedPrice).toFixed(2);
            savingsText = "You save: $".concat(priceDifference);
        }
        else if (showSavingsType === 'showSavingsPercentage') {
            var percentSaved = discountType === 'percentage'
                ? String(discountAmount)
                : ((originalPrice - discountedPrice) / originalPrice * 100).toFixed(2);
            savingsText = "You save: ".concat(percentSaved, "%");
        }
    }
    return savingsText;
}
exports.getSavingsText = getSavingsText;
/**
 * Gets the parent element of a target element that matches one of the specified price container selectors.
 * @param targetEle The target element.
 * @param priceContainerSelectors An array of price container selector strings.
 * @returns The parent element or null if none is found.
 */
function getParentElement(targetEle, productContainerSelectors) {
    var _a;
    var parentEle = ((_a = targetEle.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement) || null;
    productContainerSelectors.forEach(function (selector) {
        var closestEle = targetEle.closest(selector);
        if (closestEle) {
            parentEle = closestEle.parentElement;
        }
    });
    return parentEle;
}
exports.getParentElement = getParentElement;
/**
 * Gets the product ID from a list of price container selectors.
 * @param productContainerSelectors An array of price container selector strings.
 * @returns The product ID or null if none is found.
 */
function getPID(parentEle, targetEle, productContainerSelectors) {
    var PID = null;
    var parentElement = null;
    if (parentEle) {
        parentElement = parentEle;
    }
    else if (targetEle && productContainerSelectors) {
        parentElement = getParentElement(targetEle, productContainerSelectors);
    }
    if (parentElement) {
        var possiblePID = parentElement.getAttribute('data-pid');
        var firstPossiblePIDEle = parentElement.querySelector('[data-pid]');
        var secondPossiblePIDEle = parentElement.querySelector('[data-do-pid]');
        if (possiblePID) {
            PID = possiblePID;
        }
        else if (firstPossiblePIDEle) {
            PID = firstPossiblePIDEle.getAttribute('data-pid');
        }
        else if (secondPossiblePIDEle) {
            PID = secondPossiblePIDEle.getAttribute('data-do-pid');
        }
    }
    return PID;
}
exports.getPID = getPID;
/**
 * Gets the appropriate discount config depending on whether we're on mobile or desktop.
 * @param {DiscountObviousConfig} DiscountObviousConfig - The discount obvious options object.
 * @param {boolean} isMobile - Whether or not we're on mobile.
 * @returns {DiscountObviousConfig} - Returns the updated discount obvious options object depending on whether we're on mobile or desktop.
 */
function getMobileOrDesktopDiscountConfig(discountObviousOptions, isMobile) {
    var updatedDiscoutObviousOptions = Object.assign({}, discountObviousOptions);
    var discountText = discountObviousOptions.discountText, showSavings = discountObviousOptions.showSavings, showBadges = discountObviousOptions.showBadges, badgeText = discountObviousOptions.badgeText, badgeTextSize = discountObviousOptions.badgeTextSize, badgePosition = discountObviousOptions.badgePosition, badgeBackgroundColor = discountObviousOptions.badgeBackgroundColor, badgeTextColor = discountObviousOptions.badgeTextColor, badgeLeftOffset = discountObviousOptions.badgeLeftOffset, badgeRightOffset = discountObviousOptions.badgeRightOffset, badgeTopOffset = discountObviousOptions.badgeTopOffset, badgeBottomOffset = discountObviousOptions.badgeBottomOffset, mobileDiscountText = discountObviousOptions.mobileDiscountText, mobileShowSavings = discountObviousOptions.mobileShowSavings, mobileShowBadges = discountObviousOptions.mobileShowBadges, mobileBadgeText = discountObviousOptions.mobileBadgeText, mobileBadgeTextSize = discountObviousOptions.mobileBadgeTextSize, mobileBadgePosition = discountObviousOptions.mobileBadgePosition, mobileBadgeBackgroundColor = discountObviousOptions.mobileBadgeBackgroundColor, mobileBadgeTextColor = discountObviousOptions.mobileBadgeTextColor, mobileBadgeLeftOffset = discountObviousOptions.mobileBadgeLeftOffset, mobileBadgeRightOffset = discountObviousOptions.mobileBadgeRightOffset, mobileBadgeTopOffset = discountObviousOptions.mobileBadgeTopOffset, mobileBadgeBottomOffset = discountObviousOptions.mobileBadgeBottomOffset;
    updatedDiscoutObviousOptions.discountText = isMobile && mobileDiscountText && mobileDiscountText !== '' ? mobileDiscountText : discountText;
    updatedDiscoutObviousOptions.showSavings = isMobile ? mobileShowSavings || showSavings : showSavings;
    if (showBadges && mobileShowBadges === undefined) {
        updatedDiscoutObviousOptions.showBadges = showBadges;
        updatedDiscoutObviousOptions.badgeText = badgeText;
        updatedDiscoutObviousOptions.badgeTextSize = badgeTextSize;
        updatedDiscoutObviousOptions.badgePosition = badgePosition;
        updatedDiscoutObviousOptions.badgeBackgroundColor = badgeBackgroundColor;
        updatedDiscoutObviousOptions.badgeTextColor = badgeTextColor;
        updatedDiscoutObviousOptions.badgeLeftOffset = badgeLeftOffset;
        updatedDiscoutObviousOptions.badgeRightOffset = badgeRightOffset;
        updatedDiscoutObviousOptions.badgeTopOffset = badgeTopOffset;
        updatedDiscoutObviousOptions.badgeBottomOffset = badgeBottomOffset;
    }
    else {
        updatedDiscoutObviousOptions.showBadges = isMobile ? mobileShowBadges : showBadges;
        updatedDiscoutObviousOptions.badgeText = isMobile && mobileBadgeText && mobileBadgeText !== '' ? mobileBadgeText : badgeText;
        updatedDiscoutObviousOptions.badgeTextSize = isMobile ? mobileBadgeTextSize || badgeTextSize : badgeTextSize;
        updatedDiscoutObviousOptions.badgePosition = isMobile ? mobileBadgePosition || badgePosition : badgePosition;
        updatedDiscoutObviousOptions.badgeBackgroundColor = isMobile ? mobileBadgeBackgroundColor || badgeBackgroundColor : badgeBackgroundColor;
        updatedDiscoutObviousOptions.badgeTextColor = isMobile ? mobileBadgeTextColor || badgeTextColor : badgeTextColor;
        updatedDiscoutObviousOptions.badgeLeftOffset = isMobile ? mobileBadgeLeftOffset : badgeLeftOffset;
        updatedDiscoutObviousOptions.badgeRightOffset = isMobile ? mobileBadgeRightOffset : badgeRightOffset;
        updatedDiscoutObviousOptions.badgeTopOffset = isMobile ? mobileBadgeTopOffset : badgeTopOffset;
        updatedDiscoutObviousOptions.badgeBottomOffset = isMobile ? mobileBadgeBottomOffset : badgeBottomOffset;
    }
    return updatedDiscoutObviousOptions;
}
exports.getMobileOrDesktopDiscountConfig = getMobileOrDesktopDiscountConfig;
/**
 * Extracts the category ID from the DOM, using one of three possible methods.
 *
 * The function will search for the category ID in the following order:
 * 1. The "data-categoryid" attribute of the first element with a "container" class
 * 2. The "cgid" value in the "data-querystring" attribute of the first element with a "page" class
 * 3. The "data-categoryid" attribute of the first element with a "discountobvious-hook-js" class
 *
 * @returns {string | null} The category ID, or null if it cannot be found.
 */
function getCategoryId() {
    var _a, _b, _c;
    // Search for the first element and extract the data-categoryid property
    var containerElement = document.querySelector('.container');
    if ((_a = containerElement === null || containerElement === void 0 ? void 0 : containerElement.dataset) === null || _a === void 0 ? void 0 : _a.categoryid) {
        return containerElement.dataset.categoryid;
    }
    // Search for the second element and extract the cgid value from data-querystring property
    var pageElement = document.querySelector('.page');
    if ((_b = pageElement === null || pageElement === void 0 ? void 0 : pageElement.dataset) === null || _b === void 0 ? void 0 : _b.querystring) {
        var queryString = new URLSearchParams(pageElement.dataset.querystring);
        var cgid = queryString.get('cgid');
        if (cgid) {
            return cgid;
        }
    }
    var doHook = document.querySelector('.discountobvious-hook-js');
    if ((_c = doHook === null || doHook === void 0 ? void 0 : doHook.dataset) === null || _c === void 0 ? void 0 : _c.categoryid) {
        return doHook.dataset.categoryid;
    }
    // Return null if both operations don't find a value or the value is not truthy
    return null;
}
exports.getCategoryId = getCategoryId;
/**

Checks whether the provided discount is eligible for the given parent element.
@param {DiscountObviousConfig} discountObviousOptions - The configuration object of the discount.
@param {Element} parentEle - The parent element in which the discount is being applied.
@returns {boolean} - True if the discount is eligible for the given parent element, otherwise false.
*/
function checkEligibility(discountObviousOptions, parentEle) {
    var _a, _b, _c, _d, _e;
    var discountType = (_a = discountObviousOptions === null || discountObviousOptions === void 0 ? void 0 : discountObviousOptions.applicable_info) === null || _a === void 0 ? void 0 : _a.inclusion_type;
    var inclusionCriteria;
    var exclusionCriteria;
    if (discountType === 'PID') {
        inclusionCriteria = (_b = discountObviousOptions === null || discountObviousOptions === void 0 ? void 0 : discountObviousOptions.applicable_info) === null || _b === void 0 ? void 0 : _b.included_products;
        exclusionCriteria = (_c = discountObviousOptions === null || discountObviousOptions === void 0 ? void 0 : discountObviousOptions.applicable_info) === null || _c === void 0 ? void 0 : _c.excluded_products;
    }
    else if (discountType === 'Category') {
        inclusionCriteria = (_d = discountObviousOptions === null || discountObviousOptions === void 0 ? void 0 : discountObviousOptions.applicable_info) === null || _d === void 0 ? void 0 : _d.included_categories;
        exclusionCriteria = (_e = discountObviousOptions === null || discountObviousOptions === void 0 ? void 0 : discountObviousOptions.applicable_info) === null || _e === void 0 ? void 0 : _e.excluded_categories;
    }
    var criteriaAvailable = discountType && (inclusionCriteria || exclusionCriteria);
    if (discountType === 'all') {
        return true;
    }
    else if (criteriaAvailable && discountType === 'PID') {
        var PID = getPID(parentEle, null, null);
        if (PID && inclusionCriteria) {
            return inclusionCriteria.includes(PID);
        }
        else if (PID && exclusionCriteria) {
            return !exclusionCriteria.includes(PID);
        }
        // Err state, should be PID
        return false;
    }
    else if (criteriaAvailable && discountType === 'Category') {
        var categoryID = getCategoryId();
        if (categoryID && inclusionCriteria) {
            return inclusionCriteria.includes(categoryID);
        }
        else if (categoryID && exclusionCriteria) {
            return !exclusionCriteria.includes(categoryID);
        }
        return false;
    }
    return false;
}
exports.checkEligibility = checkEligibility;
